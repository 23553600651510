import { Link, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import NoPage from "./NoPage";
import React, { useEffect } from "react";
import "../assets/css/portfolioItem.css";
import portfolioItemData from "../assets/portfolioItemData.json";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function PortfolioItem() {
  const { item } = useParams();
  const portfolioItem = portfolioItemData.find((p) => p.Id === item);

  const images = portfolioItem.ImageUrl.map((url) => ({
    original: url,
    thumbnail: url,
  }));

  // Check if the website URL starts with "http" and adjust the link accordingly
  const websiteLink = portfolioItem.Website.startsWith("http")
    ? portfolioItem.Website
    : `https://${portfolioItem.Website}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!portfolioItem) {
    return <NoPage />;
  }

  return (
    <div className="item-wrapper">
      <h2>{portfolioItem.Title}</h2>
      <h3>{portfolioItem.Tag}</h3>
      {portfolioItem.Website && (
        <a href={websiteLink} target="_blank" rel="noopener noreferrer">
          {portfolioItem.Website}
        </a>
      )}
      <h4>Tools: {portfolioItem.Tools}</h4>
      <p>{portfolioItem.Content}</p>
      <div className="carousel-container">
        <Carousel showThumbs={false} showStatus={false}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image.original} alt={portfolioItem.Title} />
            </div>
          ))}
        </Carousel>
      </div>
      <Link to="/portfolio" className="back-link">
        <FaArrowLeft className="back-icon" />
      </Link>
    </div>
  );
}

export default PortfolioItem;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import '../assets/css/portfolio.css';

function Portfolio({ portfolioItems }) {
  const [selected, setSelected] = useState(null);

  const handleClick = () => {
    setSelected(true);
  };

  window.addEventListener('DOMContentLoaded', function() {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="portfolio-menu-wrapper">
        {portfolioItems.map((item) => (
          <Link
            to={`/portfolio/${item.Id}`}
            className="link"
            onClick={handleClick}
            key={item.Id}
          >
            <img
              src={item.ThumbnailUrl}
              alt={item.Title}
              className="portfolio-img"
            />
            <div className="overlay">
              <h3>{item.Title}</h3>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
}

export default Portfolio;
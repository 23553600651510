import React from "react";
import '../assets/css/footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <ul className="social-icons">
          <li>
            <a href="https://twitter.com/acrooney" className="social-icon" aria-label="Twitter">
              <i className="fa fa-twitter"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/acrooney/"
              className="social-icon"
              aria-label="LinkedIn"
            >
              <i className="fa fa-linkedin"></i>
            </a>
          </li>
          <li>
            <a href="https://github.com/andrewrooney01" className="social-icon" aria-label="GitHub">
              <i className="fa fa-github"></i>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;

import React from "react";
import '../assets/css/noPage.css';

function NoPage() {
  return (
    <div className="no-page-wrapper">
      <h2>No Page Found...</h2>
    </div>
  );
}

export default NoPage;

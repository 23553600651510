import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Portfolio from "./components/Portfolio";
import PortfolioItem from "./components/PortfolioItem";
import Resume from "./components/Resume";
import NoPage from "./components/NoPage";
import "./assets/css/styles.css";
import portfolioItemData from "./assets/portfolioItemData.json";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* <Route path="/home" element={<Home />} /> */}
          <Route path="/" element={<Navigate to="/portfolio" />} />
          <Route
            path="/portfolio"
            element={<Portfolio portfolioItems={portfolioItemData} />}
          />
          <Route
            path="/portfolio/:item"
            element={<PortfolioItem portfolioItems={portfolioItemData} />}
          />
          <Route path="/resume" element={<Resume />} />

          <Route path="/*" element={<NoPage />} />
        </Route>
              
      </Routes>
          
    </BrowserRouter>
  );
}
ReactDOM.render(
  <App portfolioItems={portfolioItemData} />,
  document.getElementById("root")
);

import React from "react";
import '../assets/css/resume.css';

function Resume() {
  return (
    <div className="resume-wrapper">
        <h2>Resume available upon request</h2>
        <br />
        <p>andrewrooney01@gmail.com</p>
    </div>
  );
}

export default Resume;

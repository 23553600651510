import React from "react";
import { Outlet } from "react-router-dom";
import HeaderNav from "./HeaderNav";
import Footer from "./Footer";
import PageBorder from "./PageBorder";
import '../assets/css/layout.css';

const Layout = () => {
  return (
    <>
      <div>
        <PageBorder />
        <header>
          <HeaderNav />
        </header>
        <main className="main-container">
          <Outlet />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;

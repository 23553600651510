import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../assets/css/headerNav.css";

function HeaderNav() {
  const [isChecked, setIsChecked] = useState(false);

  const handleLinkClick = () => {
    setIsChecked(false);
  };

  return (
    <header>
      <h1>Andrew Rooney</h1>

      <input id="burger" type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
      <label htmlFor="burger">
        <span></span>
        <span></span>
        <span></span>
      </label>

      <nav>
        <ul>
        <li>
            <NavLink to="/portfolio" onClick={handleLinkClick}>
              Portfolio
            </NavLink>
          </li>
          <li>
            <NavLink to="/resume" onClick={handleLinkClick}>
              Resume
            </NavLink>
          </li>

        </ul>
      </nav>
    </header>
  );
}

export default HeaderNav;

import React from "react";
import "../assets/css/pageBorder.css";

function PageBorder() {
  return (
    <>
      <div className="left"></div>
      <div className="right"></div>
      <div className="top"></div>
      <div className="bottom"></div>
    </>
  );
}

export default PageBorder;
